import User1 from "../../../assets/images/team/1.png";
import User2 from "../../../assets/images/team/2.png";
import User3 from "../../../assets/images/team/3.png";
import User4 from "../../../assets/images/team/4.png";
import User5 from "../../../assets/images/team/5.png";
import User6 from "../../../assets/images/team/6.png";
import User7 from "../../../assets/images/team/mosi.png";
import User8 from "../../../assets/images/team/8.png";
import User9 from "../../../assets/images/team/9.png";
import User10 from "../../../assets/images/team/10.png";
import User11 from "../../../assets/images/team/11.png";
import User12 from "../../../assets/images/team/12.png";
import User13 from "../../../assets/images/team/sepehr.png";

export const teamList = [
  {
    name: "Marji ",
    userImg: User5,
    position: "Artist",
    userInfo: "Graphic Design",
    twitter: "",
    mail: "mailto:marji@starvingmodels.io",
  },
  {
    name: "Tina",
    userImg: User2,
    position: "founder",
    userInfo: "Co-founder",
    twitter: "https://twitter.com/tintinjpeg",
    mail: "mailto:tina@toniclabs.io",
  },
  {
    name: "Marcos",
    userImg: User3,
    position: "3D Artist",
    userInfo: "Sr. 3D Developer",
    twitter: "",
    mail: "mailto:marcos@toniclabs.io",
  },

  {
    name: "Rob",
    userImg: User1,
    position: "founder",
    userInfo: "Co-founder",
    twitter: "https://twitter.com/elevenrm",
    mail: "mailto:robm@toniclabs.io",
  },
  {
    name: "Shey",
    userImg: User6,
    position: "Project Manager",
    userInfo: "Talent Acquisition",
    twitter: "https://twitter.com/NematSheida",
    mail: "mailto:sheyda@toniclabs.io",
  },

  {
    name: "Erick",
    userImg: User9,
    position: "Animator",
    userInfo: "Animation",
    twitter: "",
    mail: "mailto:erick@toniclabs.io",
  },
  {
    name: "Sepehr",
    userImg: User13,
    position: "Developer",
    userInfo: "Backend Developer",
    twitter: "",
    mail: "mailto:w3sepehr79@gmail.com",
  },
  {
    name: "Mosi",
    userImg: User7,
    position: "Developer",
    userInfo: "Smart contract developer",
    twitter: "",
    mail: "mailto:moslem.abbasi@gmail.com",
  },
];
