import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import mail from "../../../assets/images/mail.png";
import twitter from "../../../assets/images/twitter.png";

const TeamCard = ({ user }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  let isOtherOver = false;
  return (
    <div className="grid-item">
      <div className="team-bg">
        <div className="team-user-info">
          <Popover
            isOpen={isPopoverOpen}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={
              <div
                className="p-10"
                onMouseEnter={() => (isOtherOver = true)}
                onMouseLeave={() => {
                  isOtherOver = false;
                  setIsPopoverOpen(false);
                }}
              >
                <div className="user-info-popover">
                  <div className="d-flex align-center justify-space-between w-full">
                    <div className="user-name">
                      <h4 className="team-user-name">{user.name}</h4>
                      {/* <p className="user-position">{user.position}</p> */}
                    </div>
                    {/* <div className="social-media-user">
                      {user.mail && (
                        <a href={user.mail} target="_blank">
                          <img
                            src={mail}
                            alt="mail-icon"
                            className="cursor-pointer mr-4"
                          />
                        </a>
                      )}

                      {user.twitter && (
                        <a href={user.twitter} target="_blank">
                          <img
                            src={twitter}
                            alt="twitter-icon"
                            className="cursor-pointer ml-4"
                          />
                        </a>
                      )}
                    </div> */}
                  </div>
                  <p className="user-info-text">{user.userInfo}</p>
                </div>
              </div>
            }
          >
            <img
              alt="user-info"
              src={user.userImg}
              className={`cursor-pointer ${isPopoverOpen ? "active" : ""}`}
              onMouseEnter={() => setIsPopoverOpen(true)}
              onMouseLeave={() => {
                setTimeout(() => {
                  if (!isOtherOver) setIsPopoverOpen(false);
                }, 100);
              }}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
