import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";
import "./styles.scss";
import { teamList } from "./dataList";
import TeamCard from "./TeamCard";
import useWindowSize from "src/hooks";

const Team = () => {
  const { width } = useWindowSize();
  return (
    <section className="team-wrapper" id="team">
      {/* <SectionTitle>team</SectionTitle> */}
      <Box sx={{ flexGrow: 1 }}>
        <Container
          maxWidth="xl"
          style={{ justifyContent: "center" }}
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2300"
          data-aos-delay="700"
        >
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="team-list-wrapper">
                {width > 768 ? (
                  <div
                    className="container-element container-element-1 "
                    data-aos-duration="1500"
                    data-aos="zoom-in-up"
                  >
                    <div className="diamond-row">
                      <TeamCard user={teamList[6]} />
                      <TeamCard user={teamList[7]} />
                    </div>
                    <div className="diamond-row">
                      <TeamCard user={teamList[0]} />
                      <TeamCard user={teamList[1]} />
                      <TeamCard user={teamList[2]} />
                    </div>
                    <div className="diamond-row">
                      <TeamCard user={teamList[3]} />
                      <TeamCard user={teamList[4]} />
                    </div>
                    {/* <div className="diamond-row">
                      <TeamCard user={teamList[5]} />
                    </div> */}
                  </div>
                ) : (
                  <div
                    className="container-element container-element-1 "
                    data-aos-duration="1500"
                    data-aos="zoom-in-up"
                  >
                    
                    <div className="diamond-row">
                      <TeamCard user={teamList[6]} />
                      <TeamCard user={teamList[7]} />
                    </div>
                    <div className="diamond-row">
                      <TeamCard user={teamList[0]} />
                      <TeamCard user={teamList[1]} />
                    </div>
                    <div className="diamond-row">
                      <TeamCard user={teamList[2]} />
                      <TeamCard user={teamList[3]} />
                    </div>
                    <div className="diamond-row">
                      <TeamCard user={teamList[4]} />
                      {/* <TeamCard user={teamList[5]} /> */}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};
export default Team;
