import React from "react";
import "./styles.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SectionTitle from "../../UI/SectionTitle/SectionTitle";
import { Container } from "@mui/material";
import designImg from "../../../assets/images/service/Design.png";
import developmentImg from "../../../assets/images/service/Development.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination } from "swiper";
import useWindowSize from "src/hooks";
import JoinSectionCard from "src/components/CareerComponents/JoinSection/JoinSectionCard";
import FlipCard from "../../UI/FlipCard";

const Services = () => {
  const data = [
    {
      name: "Design",
      img: designImg,
      desc: "Partnering with a team of talented artists, specializing in a range of genres, our compa delivers exceptional design and creation services. Our artists are skilled in producing both trendy and timeless 2D and 3D digital artworks, and take pride in delivering only the highest quality work to our community and clients through close collaboration and an unwavering commitment to excellence.",
    },
    {
      name: "Development",
      img: developmentImg,
      desc: "Specializing in developing decentralized applications and NFT collections using cutting-edge blockchain technology, our team of skilled developers design and implement sophisticated dApps as well as artwork by talented artists from around the world. The comprehensive development process includes in-house and external reviews and testing to ensure a bug-free, secure, and seamless final product.",
    },
  ];
  const { width } = useWindowSize();
  return (
    <section className="servicesWrapper" id="services">
      {/* <SectionTitle>Services</SectionTitle> */}
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="xxl">
          <Grid spacing={5} lg={12} md={9} sm={12}>
            <Grid item xl={1} />
            <Grid item xl={10}>
              <Grid
                container
                spacing={10}
                className="flex-center"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1000"
              >
                <Grid item lg={8} md={8} sm={9} xs={11}>
                  <FlipCard
                    front={
                      <div className="dark-box-service">
                        <div className="background-card card-1" />
                        <div className="d-flex justify-flex-end ">
                          <div className="icon-wrapper ">
                            <img src={data[0].img} alt={"designImg"} />
                          </div>
                        </div>
                        <div>
                          <h4 className="card-title">{data[0].name}</h4>
                        </div>
                      </div>
                    }
                    back={
                      <div className="dark-box-service">
                        <div className="background-card card-3" />
                        <div className="d-flex justify-space-between align-center back">
                          <div>
                            <h3 className="text-title">{data[0].name}</h3>
                            <p>{data[0].desc}</p>
                          </div>
                        </div>
                      </div>
                    }
                  />

                  <div className="mt-35">
                    <FlipCard
                      front={
                        <div className="dark-box-service">
                          <div className="background-card card-2" />
                          <div>
                            <div className="icon-wrapper ">
                              <img src={data[1].img} alt={"designImg"} />
                            </div>
                          </div>
                          <div className="d-flex justify-flex-end ">
                            <h4 className="card-title">{data[1].name}</h4>
                          </div>
                        </div>
                      }
                      back={
                        <div className="dark-box-service">
                          <div className="background-card card-3" />
                          <div className="d-flex justify-space-between align-center back">
                            <div>
                              <h3 className="text-title">{data[1].name}</h3>
                              <p>{data[1].desc}</p>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={1} />
          </Grid>
        </Container>
        {/* <Swiper
            style={{ paddingBottom: 40 }}
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data.map((d, i) => (
              <SwiperSlide key={i}>
                <div className="p-10">
                  <FlipCard
                    front={
                      <div className="dark-box-service">
                        <div className="background-card card-1" />
                        <div className="d-flex justify-center pb-20 ">
                          <div className="icon-wrapper ">
                            <img src={d.img} alt={"designImg"} />
                          </div>
                        </div>
                        <div className="mt-10 text-center">
                          <h4 className="card-title">{d.name}</h4>
                        </div>
                      </div>
                    }
                    back={
                      <div className="dark-box-service">
                        <div className="background-card card-3" />
                        <div className="d-flex justify-space-between align-end">
                          <div>
                            <h3 className="text-title">{d.name}</h3>
                            <p>{d.desc}</p>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
      </Box>
    </section>
  );
};

export default Services;
