import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./styles.scss";
import Button from "@mui/material/Button";
import { ReactComponent as EmailIcon } from "../../../assets/images/mail.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/twitter.svg";
import footerFullImg from "../../../assets/images/footer-logo.png";

const Footer = () => {
  const navigation = [
    { name: "Services", link: "/#services" },
    { name: "Projects", link: "/#projects" },
    { name: "Team", link: "/#team" },
    {
      name: "Terms | Conditions",
      link: "/terms-and-conditions",
      isOpenNewTab: true,
    },
  ];
  const products = [
    { name: "Starving  Models", link: "https://starvingmodels.io/" },
    { name: "Boomerang.art", link: "https://boomerang.art/" },
  ];

  const company = [];
  return (
    <div className="footer-wrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Container
          maxWidth="xxl"
          data-aos="zoom-in-down"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item xl={12}>
              <Grid container spacing={5}>
                <Grid item md={6}>
                  <div className="footer-link-wrapper">
                    <div className="navigation-link-wrapper">
                      <h4>Navigation</h4>
                      <ul>
                        {navigation.map((n, i) => (
                          <li key={i}>
                            <a
                              href={n.link}
                              className="textDecoration"
                              target={n.isOpenNewTab ? "_blank" : ""}
                            >
                              <Button sx={{ my: 1, display: "block" }}>
                                {n.name}
                              </Button>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="navigation-link-wrapper">
                      <h4>Projects</h4>
                      <ul>
                        {products.map((n, i) => (
                          <li key={i}>
                            <a href={n.link} target="_blank">
                              <Button sx={{ my: 1, display: "block" }}>
                                {n.name}
                              </Button>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="footer-link-wrapper mt-20">
                    <div className="navigation-link-wrapper">
                      <h4>Social</h4>
                      <div className="d-flex social-wrapper">
                        <a href="https://twitter.com/Toniclabsio">
                          <TwitterIcon className="cursor-pointer" />
                        </a>
                        <a href="mailto:info@toniclabs.io">
                          <EmailIcon className="ml-10 cursor-pointer" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pb-30 copyRight">
                    <p>© 2018-2023 tonicLABS.io. All rights reserved.</p>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  style={{ alignItems: "end", display: "flex" }}
                >
                  <img src={footerFullImg} className="w-full full-img-logo" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Footer;
