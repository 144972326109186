import React from "react";
import { Container, Grid } from "@mui/material";
import "./styles.scss";

const MainTitleSection = () => {
  return (
    <section className="mainTitleSection" id="Home">
      <Container maxWidth="xxl">
        <Grid container spacing={2}>
          <Grid item md={1}>
            <div className="top-bg-img" />
            <div className="bottom-home-bg-img" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} data-aos="zoom-in-down" data-aos-duration="1500">
            <div className="flex-center">
              <h1 className="page-title">
                Shaping
                <br />
                The Culture
                <br />
                Of Web3 For a<br />
                <span>De</span>centralized
                <br />
                Future
              </h1>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default MainTitleSection;
