import React from "react";
import MainTitleSection from "../../components/homeComponents/MainTitleSection";
import Services from "../../components/homeComponents/Services";
import Projects from "../../components/homeComponents/Projects";
import AboutUs from "../../components/homeComponents/AboutUs";
import Team from "../../components/homeComponents/Team/Team";
import FlipCard from "../../components/UI/FlipCard";

const Home = () => {
  return (
    <div className="home-page">
      <MainTitleSection />
      <Services />
      <Projects />
      <Team />
      <AboutUs />
    </div>
  );
};

export default Home;
